<template>
  <div>
    <!-- Settings Popup 设备详情弹窗 -->
    <div class="popup-wrap">
      <div class="popup-header">
        Current Settings
        <img src="@/assets/icons/icon_close.png" alt="" @click="onClickClose"/>
      </div>
      <div>
        <div class="popup-content">

          <div class="device">
            <div class="device-name">Device Info</div>


            <div class="device-info-row">
              <PercentRing class="place-holder" :unused-color="percentRingUnusedColor"
                  :use-color="percentRingUseColor" :text-color="percentRingTextColor"
                  :percent="batteryPercent" title="Battery Level"/>
              <PercentRing :is-cartridge="isCartridge" class="place-holder" :unused-color="percentRingUnusedColor"
                  :use-color="percentRingUseColor" :text-color="percentRingTextColor" :cartridge-count="cartridgeCount"
                  :percent="oilPercent" :title="isCartridge ?'Used Cartridge' :'Oil Level'"/>
            </div>

            <div class="device-info-row" v-if="tagId" style="margin-top:25px">
              <div class="device-info-title">ID</div>
              <div class="device-info-value">{{ tagId }}</div>
            </div>

            <div class="device-info-row" v-if="deviceModel">
              <div class="device-info-title">Device Model</div>
              <div class="device-info-value">{{ deviceModel }}</div>
            </div>
            <div class="device-info-row" v-if="deviceBrand">
              <div class="device-info-title">Brand</div>
              <div class="device-info-value">{{ deviceBrand }}</div>
            </div>
            <div class="device-info-row" v-if="deviceFlavor">
              <div class="device-info-title">Flavor</div>
              <div class="device-info-value">{{ deviceFlavor }}</div>
            </div>
            <div class="device-info-row" v-if="false">
              <div class="device-info-title">Puff times</div>
              <div class="device-info-value">
                {{ puffTime }} s
              </div>
            </div>

          </div>

          <!--            <div class="preheat" v-if="writerSetting.isSupportPreheat">-->
          <div class="preheat" v-if="false">
            <div class="preheat-title">
              Preheat
              <img
                  v-if="writerSetting.isSupportPreheat"
                  src="@/assets/icons/preheat_on.png"
              />
            </div>

            <div class="device-info-row" v-if="false">
              <div class="device-info-title">Time:</div>
              <div class="device-info-value">
                {{ (writerSetting.preheatTime / 1000).toFixed(1) }}s
              </div>
            </div>
            <div class="device-info-row" v-if="false">
              <div class="device-info-title">Power:</div>
              <div class="device-info-value">
                {{
                  (
                      Math.pow(writerSetting.preheatVoltage / 1000, 2) /
                      parseFloat(rValue)
                  ).toFixed(2)
                }}w
              </div>
            </div>
          </div>
          <div v-if="false" class="preheat">
            <div class="preheat-title">
              Preheat
              <img src="@/assets/icons/preheat_off.png"/>
            </div>
          </div>

          <div class="voltage">
            <div class="voltage-title">Power Curve</div>
            <div class="d019-curve" v-if="false">Curve
              <div :style="levelClasses"><span v-if="typeOfDevice === 59">{{ level }}</span></div>
            </div>
            <!-- 温度曲线组件插入位置 -->
            <div
                id="deviceCurve"
                ref="deviceCurve"
                class="voltage-one"
                style="width:100%;height:calc(16vh)"
            />
          </div>

          <div class="voltage-three">
            <div class="voltage-number" v-for="index of 8" :key="index">
              {{ index }}s
            </div>
          </div>

          <van-button :color="syncButtonColor" style="margin-bottom:20px;border-radius: 8px;"
              @click="onApplyToHome">Apply to home default
          </van-button>


        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { initCanvasRatioWidthSize } from "@/util";
import { WriterSetting } from "@/bluetooth/BluetoothData";
import bluetoothRepository from "@/bluetooth/BluetoothRepository";
import { chartConfig, d019ChartConfig, voltageData } from "@/config/echarts-data";
import { calcMaxPower, calcVoltageFloorInt, handleHexStringToUInt16 } from "../util/utils";
import PercentRing from "@/components/PercentRing.vue";

const graphic = require("echarts/lib/util/graphic");
export default {
  name: "CurrentSetting",
  components: { PercentRing },
  props: {
    rValue: {
      type: Number,
      default: 1.0
    },
    maxValue: {
      type: Number,
      default: 12
    },
    tagId: {
      type: String,
      default: ""
    }
  },
  data() {
    //黄 0
    //白 1
    return {
      testValue: 100,
      hubCurveChart: null,
      hubChartOption: chartConfig(),
      deviceCurveChart: null,
      deviceCurveOption: chartConfig(),
      activeMode: 0,
      showProgress: true,
      errorMessage: "",
      hubSetting: this.getHubSetting(),
      loadDeviceSettingStatus: 2,
      timeoutId: -1,
      curveLevelColor: ["green", "rgba(255, 140, 0, 1)", "red"],
      curveLevelColorLinearGradient: [[
        "rgba(0, 255, 0, 1)",
        "rgba(0, 255, 0, 0.2)"
      ], [
        "rgba(255, 140, 0, 1)",
        "rgba(255, 140, 0, 0.2)"
      ], [
        "rgba(255, 0, 0, 1)",
        "rgba(255, 0, 0, 0.2)"
      ]],
      curveColor: "#86A1A9",
      curveColorLinearGradient: [
        "rgba(192, 222, 226, 1)",
        "rgba(246, 250, 250, 1)"
      ],
      currentDeviceLevel: 0
    };
  },
  created() {
  },
  mounted() {
    this.refreshDeviceCurve();
    // setInterval(()=> {
    //   if (this.testValue > 0) {
    //     this.testValue -= 1;
    //   }
    // },500)
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowSizeChanged);
  },
  computed: {
    ...mapState(["disposableHubSetting"]),
    ...mapState("bluetooth", [
      "deviceId",
      "insertDeviceName"
    ]),
    ...mapState("nfc", [
      "nfcDeviceOilBattery",
      "level",
      "writerSetting",
      "typeOfDevice"
    ]),
    ...mapState(["brandInfo", "brandInfoCacheMap"]),
    ...mapState("theme", ["percentRingUseColor", "percentRingUnusedColor", "percentRingTextColor", "syncButtonColor"]),
    deviceModel() {
      if (this.brandInfoCacheMap[this.tagId]) {
        return this.brandInfoCacheMap[this.tagId].deviceType;
      } else {
        return null;
      }
    },
    deviceBrand() {
      if (this.brandInfoCacheMap[this.tagId]) {
        return this.brandInfoCacheMap[this.tagId].brandName;
      } else {
        return null;
      }
    },
    deviceFlavor() {
      if (this.brandInfoCacheMap[this.tagId]) {
        return this.brandInfoCacheMap[this.tagId].flavor;
      } else {
        return null;
      }
    },
    batteryPercent() {
      if (this.nfcDeviceOilBattery) {
        const currentVoltagesMax = 4000;
        const currentVoltagesMin = 3500;
        const currentVoltages = handleHexStringToUInt16(this.nfcDeviceOilBattery.battery);
        if (currentVoltages <= 100) {
          return currentVoltages
        }
        if (currentVoltages >= currentVoltagesMax) {
          return 100;
        } else if (currentVoltages <= currentVoltagesMin) {
          return 0;
        }
        //(4000-3400) / (4150-3400)
        return parseFloat((((currentVoltages - currentVoltagesMin) / (currentVoltagesMax - currentVoltagesMin)) * 100).toFixed(0));
      }
      return 0;
    },
    isCartridge() {
      let result = 0;
      if (this.nfcDeviceOilBattery) {
        const { maxSecond } = this.nfcDeviceOilBattery;
        result = handleHexStringToUInt16(maxSecond) > 0;
      }

      return this.typeOfDevice === 63;
    },
    cartridgeCount() {
      if (this.isCartridge) {
        const { currentSecond } = this.nfcDeviceOilBattery;
        const currentSeconds = handleHexStringToUInt16(currentSecond);
        return currentSeconds;
      }
      return 0;
    },
    oilPercent() {
      if (this.nfcDeviceOilBattery) {
        if (this.isCartridge) {
          const { currentSecond, maxSecond } = this.nfcDeviceOilBattery;
          const currentSeconds = handleHexStringToUInt16(currentSecond);
          let maxSeconds = handleHexStringToUInt16(maxSecond);
          if (maxSeconds === 0) {
            maxSeconds = 1000
          }
          return parseFloat(((maxSeconds - currentSeconds) / maxSeconds * 100).toFixed(0));
        }
        const { currentSecond, maxSecond } = this.nfcDeviceOilBattery;
        const currentSeconds = handleHexStringToUInt16(currentSecond);
        const maxSeconds = handleHexStringToUInt16(maxSecond);
        const idleSecond = maxSeconds - currentSeconds;
        if (idleSecond <= 0) {
          return 0;
        }

        return parseFloat((((idleSecond) / maxSeconds) * 100).toFixed(0));
      }
      return 0;
    },
    puffTime() {
      if (this.nfcDeviceOilBattery) {
        const { currentSecond } = this.nfcDeviceOilBattery;
        const currentSeconds = handleHexStringToUInt16(currentSecond);
        return currentSeconds;
      }
      return "0";
    },

    levelClasses() {
      let background = "";
      console.log(this.typeOfDevice);
      if (this.level === 1) {
        background = this.typeOfDevice === 59 ? "white" : "yellow";
      } else if (this.level === 2) {
        background = "white";
      }
      return {
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        color: "#999",
        "margin-left": "10px",
        border: "1px #999 solid",
        display: "flex",
        lineHeight: "20px",
        flexDirection: "column",
        alignItems: "center",
        background
      };
    },

    isD019() {
      if (this.level > 0) {
        return true;
      }
      return false;
    }
  },
  watch: {
    syncButtonColor() {
      this.refreshDeviceCurve();
    }
    // deviceId(value) {
    //   if (value) {
    //     if (value.type === 5) { //电池修改颜色
    //       const level = ((value.productManufacture >> 4) & 0x0F) - 1;
    //       if (level < 3) {
    //         this.curveColor = this.curveLevelColor[level];
    //         this.curveColorLinearGradient = this.curveLevelColorLinearGradient[level];
    //         this.currentDeviceLevel = level;
    //         return;
    //       }
    //     }
    //   } //为空了
    //   this.curveColor = "#86A1A9";//默认颜色
    //   this.curveColorLinearGradient = [
    //     "rgba(192, 222, 226, 1)",
    //     "rgba(246, 250, 250, 1)"
    //   ];//默认渐变颜色
    // }

  },
  methods: {
    ...mapGetters("bluetooth", ["getHubSetting", "getInsertDeviceName"]),
    onApplyToHome() {
      const setting = { ...new WriterSetting(), ...this.writerSetting };
      setting.originDiyVoltage = setting.diyVoltage;
      setting.originPreheatVoltage = setting.preheatVoltage;
      setting.originPreheatTime = setting.preheatTime;
      setting.originIsSupportNfc = setting.isSupportNfc;
      setting.originIsSupportPreheat = setting.isSupportPreheat;
      this.$emit("onApplyToHome", setting);
    },
    onClickClose() {
      this.$emit("close");
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeMode = 0;
        }, 100);
      });
    },

    refreshDeviceCurve() {
      if (this.$refs.deviceCurve) {
        this.deviceCurveChart = this.$echarts.init(this.$refs.deviceCurve);
      }
      const limitMaxVoltage = calcVoltageFloorInt(this.maxValue, this.rValue);
      const maxPower = calcMaxPower(limitMaxVoltage, this.rValue);

      this.writerSetting.power = this.writerSetting.diyVoltage.map(
          item => {
            const power = Math.pow(item, 2) / 1000000 / parseFloat(this.rValue);
            return power >= maxPower ? maxPower : power;
          }
      );

      this.preheatPower = parseInt(
          Math.pow(this.writerSetting.preheatVoltage, 2) /
          1000000 /
          parseFloat(this.rValue)
      );


      const max = this.writerSetting.power.reduce((a, b) => Math.max(a, b));

      const options = d019ChartConfig(this.isD019 ? "D019" : "", this.level);
      options.yAxis.max = Math.max(this.maxValue, max);
      options.series[0].data = this.writerSetting.power;
      this.deviceCurveChart.setOption(options, true);

    },


    async reloadNfcDeviceSetting() {

      const nfcReader = navigator.nfcReader;
      if (!nfcReader) {
        return;
      }

      const event = error => {
        nfcReader.endReaderSession(false, error);
      };

      nfcReader.removeEventListener("receiveInvalidateWithError", event);
      nfcReader.addEventListener("receiveInvalidateWithError", event);

      try {
        const session = await nfcReader.beginReaderSession(2, { operate: 2 });
        console.log(`tag id = ${session.id}`);
        await session.connect();

        const dataView = await session.readMultipleBlock(20, 29);

        console.log(`读取的长度: ${dataView.byteLength}`);
        const writerSetting = new WriterSetting(dataView);

        this.loadDeviceSettingStatus = 1;
        nfcReader.endReaderSession(true, "Read Success!");

        this.$nextTick(() => {
          this.refreshDeviceCurve();
        });
      } catch (e) {
        this.loadDeviceSettingStatus = 0;
        this.errorMessage = e;
        nfcReader.endReaderSession(false, e.message);
      }
    },

    onWindowSizeChanged() {
      if (this.$refs.deviceCurve) {
        this.$nextTick(() => {
          this.deviceCurveChart.resize();
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .van-tabs__wrap {
  height: 1.6rem;
  margin-bottom: 20px;
}

/deep/ .van-tabs__nav--card {
  height: 1.6rem;
  border: none;
  background: transparent !important;
}

/deep/ .van-tab {
  margin: 0 5px !important;
  color: #939598;
  background: #e8eff2;
  border-right: none !important;
  border-bottom: none !important;
  border-radius: 5px !important;

  span {
    font-size: 18px;
  }
}

/deep/ .van-tab--active {
  background: #86a1a9 !important;
}

.popup-close {
  width: 100%;
  text-align: center;
  position: fixed;
  top: -45px;

  img {
    width: 27px;
    height: 27px;
  }
}

.popup-wrap {
  position: relative;
  overflow-y: scroll;
  display: flex;
  max-height: 90vh;
  flex-direction: column;

  .popup-header {
    z-index: 2000;
    background: white;
    position: sticky;
    top: 0;
    font-size: 21px;
    font-weight: bold;
    color: #6d6e71;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 20px 20px;

    img {
      position: absolute;
      right: 10px;
      width: 35px;
      height: 35px;
      border: 10px solid transparent;
      background-clip: padding-box;
    }
  }

  .setting-model {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .model-button {
      height: 60px;
      margin: 0 20px;
      background: #e8eff2;
      color: #939598;
      border-radius: 6px;
      font-size: 18px;
      font-weight: bold;
      width: 50%;
    }

    .model-button-active {
      background: #86a1a9;
      color: #ffffff;
    }
  }
}

.popup-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px 0 20px;

  .title {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: normal;
    color: #a7a9ac;
  }

  .info {
    font-size: 18px;
    font-weight: normal;
    color: #808285;
    display: flex;
    align-items: center;
    margin-bottom: 33px;

    img {
      margin-left: auto;
      margin-right: 3px;
      width: 19px;
      height: 19px;
    }
  }

  .info-device-load {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
  }

  .error-info {
    height: 50vh;
    color: #86a1a9;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #555555;

    .try-again {
      margin: 0 auto;
      font-size: 16px;
      width: 120px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      border: 1px solid #f1edff;
      background: #86a1a9;
      border-radius: 20px;
      margin-top: 2vh;
    }
  }

  .device {
    padding-bottom: 10px;
    border-bottom: 1px solid #E7E7E7;

    .device-name {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
    }

    .device-address {
      font-size: 18px;
      font-weight: normal;
      color: #939598;
    }
  }

  .voltage {
    margin-top: 25px;

    .voltage-title {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
    }

    .voltage-mode {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: normal;
      color: #939598;
    }
  }

  .preheat {
    margin-bottom: 15px;

    .preheat-title {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
      display: flex;

      img {
        width: 21px;
        height: 21px;
        margin-left: auto;
      }
    }

    .preheat-time {
      margin-top: 20px;
      font-size: 18px;
      font-weight: normal;
      color: #939598;
    }

    .preheat-voltage {
      margin-top: 17px;
      font-size: 18px;
      font-weight: normal;
      color: #939598;
    }
  }

  .device-info-row {
    display: flex;
    margin-top: 10px;
    font-size: 18px;

    .place-holder {
      width: 50%;
    }

    .device-info-title {
      font-weight: normal;
      color: #939598;
    }

    .device-info-value {
      margin-left: auto;
      font-weight: normal;
      color: #6d6e71;
    }
  }

  .sync-button {
    height: 45px !important;
  }

  .voltage-three {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    div {
      margin: 0 auto;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
    }
  }
}

.popup-button {
  margin: 62px 25px 27px 25px;
  height: 45px;
  line-height: 45px;
  background: #86a1a9;
  border-radius: 8px;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
}

.d019-curve {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #6d6e71;
}
</style>
