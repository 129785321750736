<script>
import { initCanvasRatioWidthSize } from "@/util";
import Tween from "@/util/animation";

export default {
  name: "PercentRing",
  props: {
    percent: {
      type: Number,
      default: 5
    },
    title: {
      type: String,
      default: ""
    },
    unusedColor: {
      type:Array,
      default:()=> {
        return ['#3290A0','#9DCCD0']
      }
    },
    useColor: {
      type:String,
      default: '#F1F2F2'
    },
    textColor: {
      type:String,
      default: '#3290A0'
    },
    isCartridge: false,
    cartridgeCount: 0

  },
  data() {
    return {
      canvas: null,
      canvasWidth: null,
      canvasHeight: null,
      currentPercent: 0,
      animateProgress: null,
      startTime: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      const canvasEl = this.$refs.canvas;
      const canvasObj = initCanvasRatioWidthSize(canvasEl, 120, 120);
      this.canvas = canvasObj.canvas;
      this.canvasWidth = canvasObj.width;
      this.canvasHeight = canvasObj.height;

      this.startTime = new Date().getTime()
      if (this.animateProgress != null) window.cancelAnimationFrame(this.animateProgress);
      this.animateProgress = requestAnimationFrame(this.start);

    });

  },
  watch: {
    percent(value) {
      if (this.animateProgress != null) window.cancelAnimationFrame(this.animateProgress);
      this.currentPercent = 0
      this.start()
    }
  },
  methods: {
    start() {
      if (this.currentPercent < this.percent) {
        this.drawRing();
        const elapsedTime = (new Date().getTime() - this.startTime);

        var progress = Math.min(elapsedTime / 4000, 1); // 限制进度在 0 到 1 之间
        // 使用 easeInOut 函数计算当前位置
        var currentPositionX = this.easeInOut(progress) * (this.percent - this.currentPercent);

        this.currentPercent += currentPositionX;
        this.animateProgress = requestAnimationFrame(this.start);
      }else {
        this.drawRing()
      }
    },
    // easeInOut 函数实现缓动效果
    easeInOut(t) {
      return t < 0.4 ? 4 * t * t * t : 1 - 2 * (1 - t) * (1 - t);
    },
    drawRing() {
      let canvas = this.canvas;
      let width = this.canvasWidth;
      let height = this.canvasHeight;

      if (this.isCartridge) {


        const percentText =  `${this.cartridgeCount}`
        canvas.fillStyle = this.textColor;
        canvas.textAlign = "center";
        canvas.font = "bold 24px Roboto";
        canvas.fillStyle = textColor;
        canvas.textBaseline = "middle";
        const textWidth = canvas.measureText(percentText).width;
        canvas.fillText(percentText, width / 2, height / 2);
        return;
      }
      const outerArcLinearGradient1 = canvas.createLinearGradient(
          0,
          0,
          width,
          height
      );
      outerArcLinearGradient1.addColorStop(0, this.unusedColor[0]);
      outerArcLinearGradient1.addColorStop(1, this.unusedColor[1]);


      const color = this.useColor;
      const textColor = this.textColor;
      canvas.clearRect(0, 0, width, height);
      canvas.lineWidth = 4;

      canvas.fillStyle = outerArcLinearGradient1;
      const startAngle = -Math.PI / 2;
      const R = width / 2 - canvas.lineWidth - 4;
      canvas.lineCap = "round";

      canvas.beginPath();
      canvas.shadowOffsetX = 2;
      canvas.shadowOffsetY = 2;
      canvas.shadowBlur = 5;
      canvas.shadowColor = "rgba(0, 0, 0, 0.1)";

      const backgroundAngle = (Math.PI / 180.0) * 360;
      canvas.strokeStyle = color;
      canvas.arc(width / 2, height / 2, R, startAngle, backgroundAngle);
      canvas.stroke();

      canvas.beginPath();
      const angle = -Math.PI / 2 + (Math.PI / 180.0) * ((200 - this.currentPercent) / 100 * 360);
      canvas.strokeStyle = outerArcLinearGradient1;
      canvas.arc(width / 2, height / 2, R, startAngle, angle, true);
      canvas.stroke();

      const smallCircleX = height / 2 + Math.cos(angle) * R;
      const smallCircleY = width / 2 + Math.sin(angle) * R;

      canvas.beginPath();
      canvas.strokeStyle = this.textColor;
      canvas.arc(smallCircleX, smallCircleY, 6, startAngle, 2 * Math.PI);
      canvas.stroke();
      canvas.beginPath();
      canvas.fillStyle = "white";
      canvas.arc(smallCircleX, smallCircleY, 5, startAngle, 2 * Math.PI);
      canvas.fill();


      const percentText = this.isCartridge ? `${this.cartridgeCount}`:`${this.percent}%`;
      canvas.textAlign = "center";
      canvas.font = "bold 24px Roboto";
      canvas.fillStyle = textColor;
      canvas.textBaseline = "middle";
      const textWidth = canvas.measureText(percentText).width;
      canvas.fillText(percentText, width / 2, height / 2);
    }
  }
};
</script>

<template>
  <div class="ring-container">
    <canvas ref="canvas"/>
    <div class="label">{{ title }}</div>
  </div>
</template>

<style scoped lang="less">
.ring-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label {
  color: #999999;
  margin-top: 8px;
  font-size: 14px;
}
</style>